import React from "react";

import json2mq from "json2mq";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import PungoServiceCard from "../../../../pungo-ui/PungoServiceCard";
import BulletedSection from "../../../../pungo-ui/common/BulletedSection";
import IALogo from "../../../../assets/PungoIcons/IAIcon.svg";
import DigitalizationLogo from "../../../../assets/PungoIcons/DigitalizationIcon.svg";
import EvaluationLogo from "../../../../assets/PungoIcons/EvaluationIcon.svg";
import PungoCustomizedCarousel from "../../../../pungo-ui/PungoCustomizedCarousel";

import breakpoints from "../../../../styles/export.module.scss";
import colors from "../../../../styles/export.module.scss";
import styles from "./index.module.scss";

const ServicesSection: React.FC = () => {
  const { t } = useTranslation();

  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.lg,
    })
  );

  const serviceCards = [
    {
      label: t("artificialIntelligence.title"),
      image: IALogo,
      color: colors.blue2,
      servicesContent: [
        t("artificialIntelligence.cleanlinessSection"),
        t("artificialIntelligence.developmentSection"),
        t("artificialIntelligence.predictionSection"),
      ],
    },
    {
      label: t("digitalization.title"),
      image: DigitalizationLogo,
      color: colors.green2,
      servicesContent: [
        t("digitalization.creationSection"),
        t("digitalization.registerSection"),
        t("digitalization.visualizationSection"),
        t("digitalization.automationSection"),
      ],
    },
    {
      label: t("evaluationSection.title"),
      image: EvaluationLogo,
      color: colors.green4,
      servicesContent: [
        t("evaluationSection.efficiencySection"),
        t("evaluationSection.calcSection"),
        t("evaluationSection.implementationSection"),
        t("evaluationSection.analysisSection"),
      ],
    },
  ];

  const getContent = () =>
    serviceCards.map(({ label, color, image, servicesContent }) => (
      <PungoServiceCard title={label} color={color} image={image} key={label}>
        {servicesContent.map((service) => (
          <BulletedSection color={color} text={service} key={service} />
        ))}
      </PungoServiceCard>
    ));

  const getContentWithCarousel = () => (
    <PungoCustomizedCarousel
      slides={getContent()}
      height={550}
      key="services"
    />
  );

  return (
    <PungoSectionCard
      title={t("ServicesSection.title")}
      description={`${t("ServicesSection.description")}`}
      id="services"
    >
      <div className={styles.container}>
        {desktopView ? getContent() : getContentWithCarousel()}
      </div>
    </PungoSectionCard>
  );
};

export default ServicesSection;
