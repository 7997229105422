// import React, { useEffect, useState } from "react";
import text from "../../../../assets/PungoIcons/textAnimated.gif";
import englishText from "../../../../assets/PungoIcons/englishTextAnimated.gif";
import monitor from "../../../../assets/PungoIcons/MonitorAnimation.gif";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import PungoCubo from "../../../../assets/PungoIcons/PungoCubo.svg";

const AnimationSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishSelected = i18n.language === "es";

  return (
    <>
      <section className={styles.container} id="start">
        <PungoSectionCard title={`${t("presentationSection.title")}`} description={`${t("presentationSection.description")}`} distribution="block">
          <div className={styles.pungoImage}>
            <img src={PungoCubo} alt="Pungo logo" className={styles.image} />
          </div>
          <div className={styles.txt}>{`${t("presentationSection.txt1")}`}</div>
        </PungoSectionCard>
        <PungoSectionCard title=" ">
          <img className={styles.gif} src={isSpanishSelected ? text : englishText} alt="Text animation" />
          <img className={styles.gif} src={monitor} alt="Monitor animation" />
        </PungoSectionCard>
      </section>
    </>
  );
};

export default AnimationSection;
