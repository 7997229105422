import React from "react";
interface BenefitsMobileContainerProps {
  color: string;
}
const BenefitsMobileContainer: React.FC<BenefitsMobileContainerProps> = (
  props
) => (
  <svg
    width="180"
    height="400"
    viewBox="0 0 207 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_0_1)">
      <path
        d="M151.5 9C153.985 9 156 6.98528 156 4.5C156 2.01472 153.985 0 151.5 0C149.015 0 147 2.01472 147 4.5C147 6.98528 149.015 9 151.5 9Z"
        fill={props.color}
      />
    </g>
    <g clipPath="url(#clip1_0_1)">
      <path
        d="M59.5 9C61.9853 9 64 6.98528 64 4.5C64 2.01472 61.9853 0 59.5 0C57.0147 0 55 2.01472 55 4.5C55 6.98528 57.0147 9 59.5 9Z"
        fill={props.color}
      />
    </g>
    <path d="M206.5 4H150.5" stroke={props.color} strokeWidth="4" />
    <path d="M60 4H4" stroke={props.color} strokeWidth="4" />
    <path d="M 205,2 V 309.37163 L 104,342.19772 2,309.37163 V 2" stroke={props.color} strokeWidth="4" />
    <defs>
      <clipPath id="clip0_0_1">
        <rect width="9" height="9" fill="white" transform="translate(147)" />
      </clipPath>
      <clipPath id="clip1_0_1">
        <rect width="9" height="9" fill="white" transform="translate(55)" />
      </clipPath>
    </defs>
  </svg>
);

export default BenefitsMobileContainer;
