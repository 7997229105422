import React from "react";
import { useTranslation } from "react-i18next";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";

import styles from "./index.module.scss";

const BenefitsSection: React.FC = () => {
  const { t } = useTranslation();

  const Benefits = [
    {
      title: t("BenefitsSection.one.title"),
      paragraph: t("BenefitsSection.one.paragraph"),
    },
    {
      title: t("BenefitsSection.two.title"),
      paragraph: t("BenefitsSection.two.paragraph"),
    },
    {
      title: t("BenefitsSection.three.title"),
      paragraph: t("BenefitsSection.three.paragraph"),
    },
    {
      title: t("BenefitsSection.four.title"),
      paragraph: t("BenefitsSection.four.paragraph"),
    },
    {
      title: t("BenefitsSection.five.title"),
      paragraph: t("BenefitsSection.five.paragraph"),
    },
  ];

  const getBenefits = () =>
    Benefits.map(({ title, paragraph }) => (
      <li>
        <div className={styles.benefits}>
          <div className={styles.title}>{title}</div>
          &nbsp;
          &nbsp;
          <div className={styles.txt}>{paragraph}</div>
        </div>
      </li>
    ));

  return (
    <PungoSectionCard title={" "} distribution="block">
      <div className={styles.container}>
        <ul>{getBenefits()}</ul>
      </div>
    </PungoSectionCard>
  );
};

export default BenefitsSection;
