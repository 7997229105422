import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";

import styles from "./index.module.scss";

const ContactForm: React.FC = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // Handle form submission here, e.g., send data to a server
    console.log("Form submitted:", { email, name, message });
  };

  return (
    <div className={styles.container}>
      <PungoSectionCard title={t("ContactSection.title")} description={`${t("ContactSection.description")}`} distribution="block">
        <form onSubmit={handleSubmit}>
          <label>{t("ContactSection.email")}:  <span className={styles.required}>*</span> </label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
          <br />
          <label>{t("ContactSection.phone")}:</label>
          <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          <br></br>
          <label>{t("ContactSection.name")}:  <span className={styles.required}>*</span></label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          <br />
          <label>{t("ContactSection.lastName")}:  <span className={styles.required}>*</span></label>
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
          <br />
          <label>{t("ContactSection.company")}:  <span className={styles.required}>*</span></label>
          <input type="text" value={company} onChange={(e) => setCompany(e.target.value)} required />
          <br />
          <label>{t("ContactSection.message")}:</label>
          <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
          <br />
          <button type="submit">Submit</button>
        </form>
      </PungoSectionCard>
    </div>
  );
};

export default ContactForm;
